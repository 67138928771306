.iframeFit{
    position: absolute;
    top:0;
    left:0;
    padding:none;
    border:none;
    outline:none;
    width:100%;
    height:100%;
    border-radius: 30px;  
  
  }
  
  #isPage{
  border-radius: 30px;
  position: relative;
  margin-left:auto;
  margin-right:auto;
  margin-top:110px;
  min-width:1000px;
  max-width:1700px;
  width:150vh;
  height:80vh;
  min-height:600px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.13), 0 5px 7px 0 rgba(0,0,0,0.14);
  
  }


  #dirButtonContainer{
  float:left;
  display:flex;
  flex-wrap:wrap;
  flex-direction: column;
  position: fixed;
  top:10%;
  }
  #dirButtonContainer button{
  position: relative;
  width:45px;
  height:45px;
  margin-left:25px;
  margin-top:50px;
  border-radius:50%;
  background:rgba(200,200,200,0.0);
  border:none
  }

#toolTitleText h1{
  font-family:'Nunito';
  font-size:20pt;
}

#toolTitleText{
  position: absolute;
  top:0;
  right:25px;
  margin-top:-10px;

}

#dirButtonContainer button:hover{
    transition-duration:0.5s;
    background:rgba(200,200,200,0.3);
}

  #textInputTitle{
  position: relative;
  top:-50px;
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  }

  #titleInputMain{
  z-index: 1000;
  padding:8px;
  border-radius:20px;
    height:20px;
  outline:none;
  border:none;
  background:rgba(230,230,230,0.3);
  font-size: 12pt;
  font-family:'Nunito';
  }
  
  #titleInputMain:hover{
  transition-duration: 0.5s;
  background:rgba(230,230,230,0.8)
  }
  
  #inputBar{
    display:flex;
    flex-wrap:nowrap;
    width:100%;
  }
  
  #funcButtonContainer{
  display:flex;
  z-index: 5;
  top:-5px;
  margin-left:20px;
  flex-wrap:wrap;
  flex-direction: row;
  position: relative;
  width:fit-content;
  border-radius:40px;
  background:transparent;
  }
  #funcButtonContainer button{
  position: relative;
  width:40px;
  height:40px;
    margin:5px;
    border-radius:50%;
    background:rgba(200,200,200,0.0);
    border:none;
  }
  
#funcButtonContainer button:hover{
    transition-duration:0.5s;
    background:rgba(200,200,200,0.5);
}

  .buttonPreset{
    z-index: 2;
    border:none;
    position: fixed;
    width:50px;
    height:50px;
    border-radius:50%;
  
  }
  #horizontal{
  position: relative;
  top:10px;left:0;

  width:100%;
  height:fit-content;
  }

  #headerTool h1{
      font-family:'Poppins';
        margin-top:10px;
  }
  #headerTool{
      position:fixed;
      top:0;
      left:0;
    width:100%;
    height:fit-content;
    text-align: center;
  }
  a{
      text-decoration:none;
      color:inherit;
  }

  #limitText{
    text-align:center;
  }
  
  #blurBlanket{
    display:none;
    
    position: fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    z-index:10000;
    background:linear-gradient(180deg,rgba(230,230,230,0.1),rgba(155, 135, 255, 0.5));
    backdrop-filter: blur(25px);
  }
  
.blurOffDirText p{
  font-family: 'Nunito';
  color:#4e4ec6;
}
.blurOffDirText{
  margin:20px;
}

  #blurBlanket h1{
    font-family: 'Poppins';
    color:#5a5aff;
  }
  #blurBlanket p{
    color:#4e4ec6;
    margin-left:auto;
    font-family: 'Nunito';
    font-size:20px;
  }
  
  #blurBlanketTextContainer{
    margin-left:auto;
    margin-right:auto;
    width:260px;
  }

  @media all and (max-width: 500px) {
    #blurBlanket{
      display:block;
    }
    #horizontal{
      display:none;
    }
  }
  